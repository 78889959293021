import React, { useState } from 'react';
import {
  Container, Row, Col, TabContent, TabPane, NavLink, NavItem, Nav
} from 'reactstrap';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Faq, Layout, Button } from '../../Components';

const Media = ({ data }) => {
  const [activeTab, setActiveTab] = useState('1');
  const [activeContent, setActiveContent] = useState('1');
  return (
    <Layout>
      <Container className="my-5">
        <Row>
          <Col md="6" className="my-5">
            <div className="Header__text pr-5 pl-3 pt-3">
              <h2>Le programme media</h2>
              <p>
                Visant à encourager le développement de l’industrie de l’audiovisuel, du cinéma et
                des jeux vidéo et bénéficiant de 56% du budget global.
              </p>
              <p>
                Il soutient financièrement le développement, la distribution et la promotion de
                projets portés par des professionnels (producteurs, distributeurs, agents de vente,
                organismes de formation et d’évènements...), notamment au-delà de leur pays
                d’origine.
              </p>
            </div>
            <div className="w-100 mb-1 mb-md-3">
              <div
                className={
                  activeTab === '1'
                    ? 'd-inline w-50 HeaderButton__tab HeaderButton_media HeaderButton__activeTab'
                    : 'd-inline w-50 HeaderButton__tab HeaderButton_media'
                }
              >
                <Button
                  color="secondary"
                  target="#"
                  text="soutien à la formation"
                  onClick={() => setActiveTab('1')}
                />
              </div>
              <div
                className={
                  activeTab === '2'
                    ? 'd-inline w-50 HeaderButton__tab HeaderButton_media HeaderButton__activeTab'
                    : 'd-inline w-50 HeaderButton__tab HeaderButton_media'
                }
              >
                <Button
                  color="secondary"
                  target="#"
                  text="éducation au cinéma"
                  onClick={() => setActiveTab('2')}
                />
              </div>
            </div>
            <div className="w-100">
              <div
                className={
                  activeTab === '3'
                    ? 'd-inline w-50 HeaderButton__tab HeaderButton_media HeaderButton__activeTab'
                    : 'd-inline w-50 HeaderButton__tab HeaderButton_media'
                }
              >
                <Button
                  color="secondary"
                  target="#"
                  text="accès aux marchés"
                  onClick={() => setActiveTab('3')}
                />
              </div>
              <div
                className={
                  activeTab === '4'
                    ? 'd-inline w-50 HeaderButton__tab HeaderButton_media HeaderButton__activeTab'
                    : 'd-inline w-50 HeaderButton__tab HeaderButton_media'
                }
              >
                <Button
                  color="secondary"
                  target="#"
                  text="soutien aux festivals"
                  onClick={() => setActiveTab('4')}
                />
              </div>
            </div>
          </Col>
          <Col md="6" className="p-3 p-md-1">
            <Img
              fluid={data.file.childImageSharp.fluid}
              className="img-fluid mx-auto d-none d-md-block"
            />
          </Col>
        </Row>
      </Container>
      <Container className="my-5" id="Content">
        <Row>
          <Col sm="4">
            <Nav vertical className="contentTabs__program">
              <NavItem>
                <NavLink
                  href="#"
                  className={activeContent === '1' ? 'active' : ''}
                  onClick={() => {
                    setActiveContent('1');
                  }}
                >
                  {activeTab === '1' ? 'Formations éligibles' : 'Pourquoi?'}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={activeContent === '2' ? 'active' : ''}
                  onClick={() => {
                    setActiveContent('2');
                  }}
                >
                  Pour qui ?
                </NavLink>
              </NavItem>
              {activeTab !== '2' ? (
                <>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={activeContent === '3' ? 'active' : ''}
                      onClick={() => {
                        setActiveContent('3');
                      }}
                    >
                      Projets
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={activeContent === '4' ? 'active' : ''}
                      onClick={() => {
                        setActiveContent('4');
                      }}
                    >
                      FAQ
                    </NavLink>
                  </NavItem>
                </>
              ) : (
                <NavItem>
                  <NavLink
                    href="#"
                    className={activeContent === '3' ? 'active' : ''}
                    onClick={() => {
                      setActiveContent('3');
                    }}
                  >
                    FAQ
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </Col>
          <Col md="8">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <p>
                  Cet appel soutient les formations européennes et internationales qui permettent de
                  faciliter l’acquisition et le renforcement des qualifications et compétences des
                  professionnels de l’audiovisuel et le développement de réseaux.
                </p>
                <TabContent activeTab={activeContent}>
                  <TabPane tabId="1">
                    <div className="Header__text pr-5 pl-3 pt-3">
                      <h4 className="text-uppercase">Formations éligibles</h4>
                      <p>
                        Formations qui visent le développement des publics, de marketing, de
                        nouveaux modes de distribution et d’exploitation.
                      </p>
                      <p>
                        Formations de gestion financière et commerciale dans le but d’améliorer la
                        capacité d’accès au financement, les instruments de financement et les
                        nouveaux modèles commerciaux.
                      </p>
                      <p>
                        Formation en matière de développement et de production des œuvres
                        audiovisuelles.
                      </p>
                      <p>
                        Formations intégrant les possibilités offertes par les technologies
                        numériques pour garantir une adaptation aux dernières évolutions du marché.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="Header__text pr-5 pl-3 pt-3">
                      <h4 className="text-uppercase">Pour qui?</h4>
                      <p>
                        L’appel soutien aux formations est destiné aux organismes, publics ou
                        privés, à but lucratif ou non, des secteurs audiovisuel, cinéma, jeu vidéo,
                        qui souhaitent développer la mise en réseau des professionnels européens.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="3">
                    <div className="Header__text pr-5 pl-3 pt-3">
                      <h4 className="text-uppercase">Projets</h4>
                      <p>
                        <b>Catégorie 1</b>
                        : actions européennes ayant pour but d’aider les
                        professionnels à acquérir des aptitudes et compétences ou à les améliorer en
                        vue d’une activité exercée principalement en Europe.
                      </p>
                      <p>
                        Pour cette catégorie Le financement européen peut atteindre 60 % du total
                        des coûts éligibles.
                      </p>
                      <p>
                        <b>Catégorie 2</b>
                        : actions internationales visant à renforcer l’expertise,
                        les connaissances et les capacités des professionnels européens en vue d’une
                        activité exercée en dehors de l’Europe, par l’intermédiaire de la mise en
                        réseau et de la collaboration avec des professionnels non européens.
                      </p>
                      <p>
                        <b>Catégorie 3</b>
                        : actions régionales destinées à renforcer la capacité des
                        professionnels provenant de pays à faible capacité de production.
                      </p>
                      <p>
                        Pour les actions internationales et régionales, Le financement européen peut
                        atteindre 80 % du total des coûts éligibles.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="4">
                    <Faq />
                  </TabPane>
                </TabContent>
              </TabPane>
              <TabPane tabId="2">
                <p>
                  Cet appel vise à promouvoir l’éducation cinématographique et à accroître la
                  connaissance, par le public, essentiellement les jeunes, des œuvres audiovisuelles
                  européennes.
                </p>
                <TabContent activeTab={activeContent}>
                  <TabPane tabId="1">
                    <div className="Header__text pr-5 pl-3 pt-3">
                      <h4 className="text-uppercase">Pourquoi?</h4>
                      <p>Soutenir les expériences d’éducation au cinéma.</p>
                      <p>Développer des actions innovantes en lien avec les outils numériques.</p>
                      <p>
                        Encourager la circulation des films européens à l’échelle mondiale et celle
                        des films internationaux dans l’Union européenne sur toutes les plateformes
                        de distribution à travers des projets de coopération internationale dans le
                        secteur audiovisuel.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="Header__text pr-5 pl-3 pt-3">
                      <h4 className="text-uppercase">Pour qui?</h4>
                      <p>
                        L’appel éducation au cinéma est destiné aux acteurs du secteur
                        cinématographique européen, public ou privé, à but lucratif ou non,
                        souhaitant travailler, avec d’autres acteurs européens, autour de nouvelles
                        approches pour stimuler l’intérêt et l’accès aux films européens.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="3">
                    <Faq />
                  </TabPane>
                </TabContent>
              </TabPane>
              <TabPane tabId="3">
                <p>
                  L’appel accès aux marchés soutient les actions européennes qui permettent de
                  promouvoir, faciliter et encourager les échanges entre les professionnels de
                  l’audiovisuel.
                </p>
                <TabContent activeTab={activeContent}>
                  <TabPane tabId="1">
                    <div className="Header__text pr-5 pl-3 pt-3">
                      <h4 className="text-uppercase">Pourquoi?</h4>
                      <p>
                        Améliorer la capacité des opérateurs du secteur audiovisuel à concevoir des
                        œuvres dotées d’un potentiel de diffusion dans le monde.
                      </p>
                      <p>Favoriser les coproductions européennes et internationales.</p>
                      <p>
                        Encourager la mise en réseau et l’adaptation des professionnels aux
                        mutations technologiques et commerciales du secteur.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="Header__text pr-5 pl-3 pt-3">
                      <h4 className="text-uppercase">Pour qui?</h4>
                      <p>
                        Cet appel est destiné aux organisations, publiques ou privées, à but
                        lucratif ou non du secteur de l’audiovisuel, du cinéma ou du jeu vidéo,
                        cherchant à renforcer la capacité des professionnels à opérer dans un
                        environnement européen et international compétitif.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="3">
                    <div className="Header__text pr-5 pl-3 pt-3">
                      <h4 className="text-uppercase">Projets</h4>
                      <p>
                        <b>Action 1</b>
                        : Marchés pour les professionnels européens de l’audiovisuel
                        (marchés de vente, de coproduction, de financement, forum de pitch...). Une
                        attention particulière sera accordée aux initiatives portant sur les courts
                        métrages, les jeux vidéo, le cross media et les séries TV.
                      </p>
                      <p>
                        Pour cette catégorie Le financement européen peut atteindre 60 % du total
                        des coûts éligibles.
                      </p>
                      <p>
                        <b>Action 2</b>
                        : Activités de promotion des œuvres européennes.
                      </p>
                      <p>
                        <b>Financement</b>
                        : La contribution financière de l’Union ne peut dépasser :
                      </p>
                      <p>
                        - 60 % du total des coûts éligibles de l’action lorsque celle-ci se déroule
                        dans des pays participant au sous-programme MEDIA.
                      </p>
                      <p>
                        - 80% du total des coûts éligibles de l’action lorsque celle-ci se déroule
                        dans des pays ne participant pas au sous-programme MEDIA.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="4">
                    <Faq />
                  </TabPane>
                </TabContent>
              </TabPane>
              <TabPane tabId="4">
                <p>
                  Cet appel vise à soutenir les festivals dont le programme présente une part
                  importante d’œuvres européennes et visant le développement des connaissances et de
                  l’intérêt des publics.
                </p>
                <TabContent activeTab={activeContent}>
                  <TabPane tabId="1">
                    <div className="Header__text pr-5 pl-3 pt-3">
                      <h4 className="text-uppercase">Pourquoi?</h4>
                      <p>
                        Encourager la diversité culturelle et géographique dans la programmation des
                        festivals.
                      </p>
                      <p>
                        Soutenir la collaboration et le partenariat avec d’autres festivals du film
                        au-delà des frontières.
                      </p>
                      <p>
                        Avoir recours aux derniers outils et technologies numériques pour la
                        sensibilisation et le développement des publics.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="Header__text pr-5 pl-3 pt-3">
                      <h4 className="text-uppercase">Pour qui?</h4>
                      <p>
                        L’appel soutien au festival concerne les organismes européens du secteur
                        audiovisuel et cinématographique organisant un festival de film à
                        programmation fortement européenne, et souhaitant renforcer la dimension de
                        leur festival et élargir leur audience.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="3">
                    <div className="Header__text pr-5 pl-3 pt-3">
                      <h4 className="text-uppercase">Projets</h4>
                      <p>
                        Les programmes des festivals soutenus doivent comprendre au moins 70 % ou
                        100 films ou encore 400 courts métrages provenant des pays participants au
                        sous-programme MEDIA, dont au moins 50% d’origine non nationale,
                        représentant au moins 15 pays MEDIA.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="4">
                    <Faq />
                  </TabPane>
                </TabContent>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Media;
export const query = graphql`
  query {
    file(relativePath: { eq: "media.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
